export class Applications {
  static readonly JOBSITE_MANAGEMENT = new Applications(
    'jobsite_management',
    'jobsites-management',
  )
  static readonly PILES = new Applications('analysis', 'analysis')
  static readonly FILES_EXPLORER = new Applications(
    'files_explorer',
    'files-explorer',
  )
  static readonly TELEMATICS = new Applications(
    'telematics',
    'telematics/v2',
    true,
  )

  private constructor(
    public readonly keycloakName: string,
    public readonly url: string,
    public readonly externalUrl?: boolean,
  ) {}
}

export const applicationsConfig = [
  Applications.JOBSITE_MANAGEMENT,
  Applications.PILES,
  Applications.FILES_EXPLORER,
  Applications.TELEMATICS,
]
