// Build information, automatically generated by `ng-info`
const build = {
    version: "22.3.1",
    timestamp: "Wed Apr 02 2025 15:05:19 GMT+0200 (Central European Summer Time)",
    message: null,
    git: {
        user: null,
        branch: "HEAD",
        hash: "b72414",
        fullHash: "b72414d88bd65634741cdabf2b7454aec724c022"
    }
};

export default build;